import { useState } from 'react'

interface Props {
    setSearch?: Function
}


let searchTimeout: any
const Search = (props: Props) => {
    const [search, setSearch] = useState('')
    const handleSearch = (e: any) => {
        const { value } = e.target
        setSearch(value)
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }
        searchTimeout = setTimeout(() => {
            if (props.setSearch) {
                props.setSearch(value)
            }
        }, 750)
    }
    return (
        <div>
            <input type="text" id="search" onChange={handleSearch} placeholder="Search..." value={search} />
        </div>
    )
}
export default Search
