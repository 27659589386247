
export interface EnvType {
  adminUserGroup?: string,
  agentUserGroup?: string,
  csrUserGroup?: string,
  attachPolicyUrl?: string,
  attachQuoteUrl?: string,
  basePath?: string,
  backofficeIdentityPoolId?: string,
  cognitoClientId?: string
  cognitoUserPoolId?: string
  createAgentUser?: string
  deployedBuildPath?: string
  documentStorageBucket?: string
  downloadPolicyUrl?: string
  downloadQuoteUrl?: string
  environment?: string
  facadeBaseUrl?: string
  getAllUsers?: string
  getUser?: string
  getAllMembers?: string
  getMember?: string
  getAllPolicies?: string
  getPoliciesByUsername?: string
  getPolicy?: string
  getAllQuotes?: string
  getQuotesByUsername?: string
  getQuote?: string
  getAllSessions?: string
  getSession?: string,
  getSessionByUsername?: string
  getEventsByUsername?: string,
  resetBackofficeUserPassword?: string,
  resetCustomerUserPassword?: string
}

export const env: EnvType = {
  adminUserGroup: process.env.REACT_APP_ADMIN_USER_GROUP,
  agentUserGroup: process.env.REACT_APP_AGENT_USER_GROUP,
  csrUserGroup: process.env.REACT_APP_CSR_USER_GROUP,
  attachPolicyUrl: process.env.REACT_APP_ATTACH_POLICY_URL,
  attachQuoteUrl: process.env.REACT_APP_ATTACH_QUOTE_URL,
  backofficeIdentityPoolId: process.env.REACT_APP_BACKOFFICE_IDENTITY_POOL_ID,
  basePath: process.env.REACT_APP_BASE_PATH,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  cognitoUserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  createAgentUser: process.env.REACT_APP_CREATE_AGENT_USER,
  deployedBuildPath: process.env.REACT_APP_DEPLOYED_BUILD_PATH || '/',
  documentStorageBucket: process.env.REACT_APP_DOCUMENT_STORAGE_BUCKET,
  downloadPolicyUrl: process.env.REACT_APP_DOWNLOAD_POLICY_URL,
  downloadQuoteUrl: process.env.REACT_APP_DOWNLOAD_QUOTE_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  facadeBaseUrl: process.env.REACT_APP_FACADE_BASE_URL,
  getAllUsers: process.env.REACT_APP_BACKOFFICE_GET_ALL_USERS,
  getUser: process.env.REACT_APP_BACKOFFICE_GET_USER,
  getAllMembers: process.env.REACT_APP_CUSTOMER_GET_ALL_USERS,
  getMember: process.env.REACT_APP_CUSTOMER_GET_USER,
  getAllPolicies: process.env.REACT_APP_GET_ALL_POLICIES,
  getPoliciesByUsername: process.env.REACT_APP_GET_POLICIES_BY_USERNAME,
  getPolicy: process.env.REACT_APP_GET_POLICY,
  getAllQuotes: process.env.REACT_APP_GET_ALL_QUOTES,
  getQuotesByUsername: process.env.REACT_APP_GET_QUOTES_BY_USERNAME,
  getQuote: process.env.REACT_APP_GET_QUOTE,
  getAllSessions: process.env.REACT_APP_GET_ALL_SESSIONS,
  getSession: process.env.REACT_APP_GET_SESSION,
  getSessionByUsername: process.env.REACT_APP_GET_SESSION_BY_USERNAME,
  getEventsByUsername: process.env.REACT_APP_GET_TRAIL_EVENTS,
  resetBackofficeUserPassword: process.env.REACT_APP_BACKOFFICE_RESET_USER_PASSWORD,
  resetCustomerUserPassword: process.env.REACT_APP_CUSTOMER_RESET_USER_PASSWORD,
}