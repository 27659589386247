import React from 'react'
import Auth from '@aws-amplify/auth'
import { withAuthenticator } from 'aws-amplify-react'
import '@aws-amplify/ui/dist/style.css'

import Manager from './Manager'
import { env } from './env'


Auth.configure({
  region: 'us-east-2',
  userPoolId: env.cognitoUserPoolId,
  userPoolWebClientId: env.cognitoClientId,
  mandatorySignIn: true,
  identityPoolId: env.backofficeIdentityPoolId
})


const App = () => {
  return (
    <div className="App">
      <Manager />
    </div>
  );
}

export default withAuthenticator(App, true)
