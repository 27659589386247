import { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Checkbox from '@mui/material/Checkbox'
import { CARRIERS } from '../util'


interface DraggableItem {
    active: boolean
    id: string
    content: string
}

interface DraggableListProps {
    items: DraggableItem[]
    label?: string
    onChange: any
    selectable?: boolean
}

const TILE_COLORS = [
    '#2eb4b6',
    '#2eb69d',
    '#2eb682',
    '#2e99b6',
    '#2e55b6',
    '#6c88cb',
    '#4B2EB6',
    '#816ccb'
]


const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

const grid = 8

const getItemStyle = (isDragging: boolean, draggableStyle: any, active: boolean, selectable: boolean) => ({
    userSelect: 'none',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
    background: isDragging ? '#eeb172' : '#2eb4b6',
    borderRadius: '5px',
    fontWeight: 500,
    fontSize: '17px',
    opacity: (active || !selectable) ? 1 : 0.4,
    textAlign: 'center',
    width: 100,
    ...draggableStyle,
})
const getListStyle = (isDraggingOver: boolean) => ({
    background: '#fff',
    border: '1px solid #AAA',
    borderRadius: '5px',
    display: 'flex',
    flexDireciton: 'row',
    marginTop: '10px',
    padding: '11px',
    paddingRight: '4px',
    position: 'relative' as 'relative',
    overflow: 'visible',
})
const labelStyle = {
    background: '#fcfcfc',
    color: '#777',
    left: '15px',
    margin: 0,
    padding: '0 5px',
    position: 'absolute' as 'absolute',
    top: '-11px',
}

const DraggableList = (props: DraggableListProps) => {
    const { label, onChange, selectable } = props
    const [items, setItems] = useState<any[]>([...props.items])

    const getTileColor = (carrier: string) => {
        let carriers: string[] = []
        CARRIERS.forEach(c => carriers.push(c))
        CARRIERS.forEach(c => carriers.push(`${c} (manual)`))
        let idx = carriers.indexOf(carrier)
        if (idx < 0 || idx > TILE_COLORS.length - 1) {
            idx = 0
        }
        return TILE_COLORS[idx]
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return
        }

        const reordededItems = reorder(
            items,
            result.source.index,
            result.destination.index
        )
        setItems([...reordededItems])
        onChange(reordededItems.map(i => i.content))
    }

    const onSelect = (e: any, itemId: string, idx: number) => {
        const reordered = Array.from(items)
        let removed: any[] = []
        reordered[idx].active = e.target.checked
        reordered.forEach(item => {
            if (!item.active || !selectable) {
                removed = removed.concat(reordered.splice(reordered.indexOf(item), 1))
            }
        })
        setItems([...reordered, ...removed])
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            {items && <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}>
                        {label && <p style={labelStyle}>{label}</p>}
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided: any, snapshot: any) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            {...provided.draggableProps.style, background: getTileColor(item.id)},
                                            item.active,
                                            selectable || false,
                                        )}>
                                        {selectable && (
                                            <Checkbox sx={{marginBottom: '4px', padding: 0, paddingRight: '10px'}} onChange={(e) => onSelect(e, item.id, index)} checked={item.active} />
                                        )}
                                        <p style={{display: 'inline-block', textTransform: 'capitalize'}}>{`${(index + 1)}. ${item.content}`}</p>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>}
        </DragDropContext>
    )
}

export default DraggableList
