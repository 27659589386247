import { useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Creatable from 'react-select/creatable'
import ProductsList from '../components/ProductsList'

const CARRIER = 'chubb'


interface RowProps {
    addNewGildCodes?: any
    active?: boolean
    API?: boolean
    classCode: string
    code: string
    Description: string
    'Digital Code': string
    gildClassesMap: any
    gildCode?: string[]
    gildMenuItems: any
    manual?: {[key: string]: boolean}
    products?: {[key: string]: boolean}
    update?: any
    // updateProducts?: any
}


// const Product = (props: any) => {
//     const { checked, code, color, onChange, product } = props
//     const lower = product.toLowerCase().replace(' ', '-')
//     return (
//         <>
//             <Checkbox 
//                 id={`${lower}-${code}`}
//                 checked={checked}
//                 color={color}
//                 onChange={(e, checked) => onChange(e, checked, lower)} />
//             <label htmlFor={`${lower}-${code}`}>{product}</label>
//         </>
//     )
// }


const Row = (props: RowProps) => {
    const { addNewGildCodes, classCode, gildClassesMap, gildMenuItems, update } = props
    const code = props['Digital Code']
    const description = props['Description']
    const [active, setActive] = useState(props.active || false)
    const [gildCode, setGildCode] = useState(props.gildCode || [])
    const [newGildCode, setNewGildCode] = useState('')
    const [manual, setManual] = useState(props.manual)
    const [products, setProducts] = useState(props.products)

    const descriptionStyle: any = {}
    if (!active) {
        descriptionStyle.color = 'grey'
        descriptionStyle.fontStyle = 'italic'
    }

    const selectStyle = {
        multiValueLabel: (base: any) => ({
            ...base,
            fontSize: 14
        })
    }

    const onActiveChange = (e: any, checked: boolean) => {
        update(code, {active: checked})
        setActive(checked)
    }

    const onGildCodeChange = (sel?: any) => {
        // Auto select active
        if (!gildCode.length && sel.length) {
            // If selecting a description for the first time, automatically check active
            update(code, {active: true})
            setActive(true)
        }
        // Check if there are new codes
        if (newGildCode) {
            const newVal = addNewGildCodes(sel.map((s: any) => s.value))
            update(code, {gildCode: newVal})
            setGildCode(newVal)
            return
        }
        // Set new value
        const newVal = sel.map((s: any) => s.value)
        update(code, {gildCode: newVal})
        setGildCode(newVal)
    }

    const onManualChange = (e: any, checked: boolean, product: string) => {
        update(code, {manual: { ...manual, [product]: checked }})
        setManual({ ...manual, [product]: checked })
    }

    const onProductsChange = (e: any, checked: boolean, product: string) => {
        update(code, {products: { ...products, [product]: checked }})
        setProducts({ ...products, [product]: checked })
    }
    
    return (
        <TableRow key={code}>
            <TableCell>
                <Checkbox id={`active-${code}`} checked={active} onChange={onActiveChange} />
            </TableCell>
            <TableCell>{code}</TableCell>
            <TableCell><label htmlFor={`active-${code}`} style={descriptionStyle}>{description}</label></TableCell>
            <TableCell>
                <Creatable
                    isClearable
                    isMulti
                    options={gildMenuItems}
                    onChange={(sel) => onGildCodeChange(sel)}
                    onInputChange={(newVal) => setNewGildCode(newVal)}
                    styles={selectStyle}
                    value={gildCode.filter(c => !!c).map(c => ({ value: c, label: gildClassesMap[c] }))} />
            </TableCell>
            <TableCell>
                <ProductsList color="primary" classCode={classCode} onProductsChange={onProductsChange} products={products} />
            </TableCell>
            <TableCell>
                <ProductsList color="secondary" classCode={classCode} onProductsChange={onManualChange} products={manual} />
            </TableCell>
        </TableRow>
    )
}

export default Row