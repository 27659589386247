import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@material-ui/core/Grid'
import { Title } from 'react-admin'
import Typography from '@mui/material/Typography'

const CARRIER_NAME = 'Progressive'
const SUPPORTED_STATES = ['AZ', 'DE', 'GA', 'MI', 'NC', 'OH']
const cardStyle = {
    backgroundColor: 'white',
    display: 'flex',
    height: 600
}
const contentStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center'
}

const Progressive = (props: any) => {
    return (
        <Card style={cardStyle}>
            <Title title={CARRIER_NAME} />
            <CardContent style={contentStyle}>
                <div style={{ }}>
                    <Grid container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center">
                        <Grid item>
                            <Typography sx={{ margin: '0 auto', textAlign: 'center', width: '50%' }}>
                                Progressive is available for all <strong>Commerical Auto</strong> policies in the following states and will automatically be added whenever a new Gild Code is created:
                                <br /><em>{SUPPORTED_STATES.join(', ')}</em>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
}

export default Progressive