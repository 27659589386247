import React from 'react'
import { Card, CardContent } from '@material-ui/core'
import packageJson from '../package.json'
import { env } from './env'

const envAbbrev: any = {
  dev: 'd',
  staging: 's',
  prod: 'p'
}

const abbrev = envAbbrev[env.environment || '']

export default () => (
  <Card>
    <CardContent>
      <img src={`${env.deployedBuildPath}gild-insurance.svg`} />
      <p className="Dashboard__version">v.{packageJson.version}-{abbrev}</p>
    </CardContent>
  </Card>
)
