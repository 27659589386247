import { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import Fuse from 'fuse.js'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Pagination from '@mui/material/Pagination'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DeleteIcon from '@mui/icons-material/Delete'
import { Title } from 'react-admin'
import CreatableSelect from 'react-select/creatable'
import authProvider from '../auth'
import DraggableList from '../components/DraggableList'
import { env } from '../env'
import Footer from '../Footer'
import { makeHeaders } from '../util'
import Search from '../Search'


let autoSaveInterval: any
let classCodesResp: any
let productsResp: any

const carriers = ['chubb', 'coterie', 'attune', 'employers', 'hartford', 'hiscox']
const errorStyles = {
    backgroundColor: 'rgba(255,0,0,.2)',
    border: '5px solid red'
}


interface Props {
    isActive?: boolean
    deleteGildCode?: any
    token?: string
}

interface RowProps {
    active?: boolean
    carrierOrder?: any
    deleteGildCode?: any
    description: string
    fullDescription: string
    gildId: string
    keywords: any[]
    keywordOptions: string[]
    products?: any
}


const ProductRow = (props: any) => {
    const { carrierOrder = [], onChange, product, products = [] } = props
    if (!products.length) {
        return null
    }
    products.forEach((p: string) => {
        if (carrierOrder.indexOf(p) === -1) {
            // If a product has not been assigned a carrier order, add it to the end
            carrierOrder.push(p)
        }
    })    
    const items: any[] = carrierOrder.map((p: string) => {
        if (products.indexOf(p) > -1) {
            // Only include carriers that are currently assigned products
            return {id: p, content: p}
        }
    }).filter((i: any) => !!i)

    return (
        <div style={{ display: 'flex', marginBottom: '5px' }}>
            <DraggableList
                onChange={(items: string[]) => onChange(product.toLowerCase(), items)}
                selectable={false}
                items={items}
                label={props.product} />
        </div>
    )
}


const Row = (props: RowProps) => {
    const { carrierOrder, deleteGildCode, gildId, keywordOptions, products } = props
    const [active, setActive] = useState<boolean>(props.active || true)
    const [description, setDescription] = useState(props.description)
    const [fullDescription, setFullDescription] = useState(props.fullDescription)
    const [keywords, setKeywords] = useState<any[]>(props.keywords || [])
    const [open, setOpen] = useState(false)
    const [tab, setTab] = useState(0)

    const arrowStyle: any = {
        color: '#e6831c',
        cursor: 'pointer',
        position: 'relative',
        top: '7px',
        transition: 'transform 350ms ease-out'
    }
    if (open) {
        arrowStyle['transform'] = 'rotate(90deg)'
    }

    const descriptionStyle: any = {}
    if (!active) {
        descriptionStyle.color = 'grey'
        descriptionStyle.fontStyle = 'italic'
    }

    const onActiveChange = (e: any, checked: boolean) => {
        classCodesResp[gildId].active = checked
        setActive(checked)
    }

    const onCarrierOrderChange = (product: string, order: {[carrier: string]: boolean}) => {
        classCodesResp[gildId].carrier_order[product] = order
    }

    const onDelete = () => {
        if (window.confirm(`Are you sure you want to delete ${gildId}?`)) {
            deleteGildCode(gildId)
        }
    }

    const onDescriptionChange = (e: any) => {
        classCodesResp[gildId].Description = e.target.value
        setDescription(e.target.value)
    }

    const onFullDescriptionChange = (e: any) => {
        classCodesResp[gildId].FullDescription = e.target.value
        setFullDescription(e.target.value)
    }

    const onKeywordsChange = (newVal: any) => {
        const update = newVal.map((v: any) => v.value)
        classCodesResp[gildId].Keywords = update
        setKeywords(update)
    }

    // Calculate the number of products that currently have a carrier order assigned to them
    const activeProducts = 
        (products?.bop?.length && carrierOrder?.bop?.length ? 1 : 0) + 
        (products?.gl?.length && carrierOrder?.gl?.length ? 1 : 0) + 
        (products?.pl?.length && carrierOrder?.pl?.length ? 1 : 0) + 
        (products?.wc?.length && carrierOrder?.wc?.length ? 1 : 0) + 
        (products?.commercial_auto?.length && carrierOrder?.commercial_auto?.length ? 1 : 0)

    const boxStyle = {
        padding: '15px 20px'
    }
    const textInputStyle = {
        width: '100%',
        '& input': {
            fontSize: '0.85em !important',
        }
    }
    
    return (
        <>
            <TableRow key={gildId}>
                <TableCell>
                    <Checkbox id={`active-${gildId}`} checked={active} onChange={onActiveChange} />
                </TableCell>
                <TableCell>
                    <p style={descriptionStyle}>{gildId}</p></TableCell>
                <TableCell>
                    <TextField sx={textInputStyle} label="Description" onChange={onDescriptionChange} variant="outlined" value={description} />
                </TableCell>
                <TableCell>
                    <TextField sx={textInputStyle} label="Full Description" onChange={onFullDescriptionChange} variant="outlined" value={fullDescription} />
                </TableCell>
                <TableCell>
                    <CreatableSelect
                        isMulti
                        options={keywordOptions.map(k => ({value: k, label: k}))}
                        onChange={onKeywordsChange}
                        value={keywords.map(k => ({value: k, label: k}))} />
                </TableCell>
                <TableCell>
                    <DeleteIcon sx={{cursor: 'pointer'}} color="secondary" onClick={onDelete} />
                </TableCell>
            </TableRow>
            <TableRow sx={{backgroundColor: '#fcfcfc'}} key={`${gildId}-2`}>
                <TableCell sx={{paddingBottom: '22px', paddingLeft: '45px', paddingTop: '10px'}} colSpan={5}>
                    <ArrowForwardIosIcon onClick={() => setOpen(!open)} style={arrowStyle} />
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tab} onChange={(e: any, newVal: number) => setTab(newVal)}>
                                <Tab label={`Carriers (${activeProducts})`} value={0} />
                                <Tab label="Follow-Ups" value={1} />
                                <Tab label="Additional Codes" value={2} />
                            </Tabs>
                        </Box>
                        {tab === 0 && (
                            <Box sx={boxStyle}>
                                <p><em>Drag and drop the boxes below to change the <b>carrier order</b> for each product type.</em></p>
                                <p><em>Additional carriers can be added via the carrier pages.</em></p>
                                <ProductRow carrierOrder={carrierOrder?.bop} onChange={onCarrierOrderChange} product="BOP" products={products?.bop} />
                                <ProductRow carrierOrder={carrierOrder?.gl} onChange={onCarrierOrderChange} product="GL" products={products?.gl} />
                                <ProductRow carrierOrder={carrierOrder?.pl} onChange={onCarrierOrderChange} product="PL" products={products?.pl} />
                                <ProductRow carrierOrder={carrierOrder?.wc} onChange={onCarrierOrderChange} product="WC" products={products?.wc} />
                                <ProductRow carrierOrder={carrierOrder?.commerical_auto} onChange={onCarrierOrderChange} product="Commerical Auto" products={products?.commerical_auto} />
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}


const Gild = (props: Props) => {
    const { deleteGildCode, isActive, token } = props
    const [classCodes, setClassCodes] = useState<any[]>([])
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [keywordOptions, setKeywordOptions] = useState<string[]>([])
    const [lastSaved, setLastSaved] = useState('')
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(10)
    const [saving, setSaving] = useState(false)
    const [search, setSearch] = useState('')

    const paginateResults = () => {
        if (!classCodesResp) {
            return
        }
        let allKeywords: string[] = []
        const start = 100 * (page - 1)
        const end = 100 * page
        // === Modify classCodesResp ===
        // Make sure all entries in carrier_order are valid products
        // Object.keys(classCodesResp).forEach(gildId => {
        //     if (Array.isArray(classCodesResp[gildId].carrier_order)) {
        //         delete classCodesResp[gildId].carrier_order
        //     }
        //     if (!classCodesResp[gildId].carrier_order) {
        //         classCodesResp[gildId].carrier_order = productsResp[gildId]
        //     }
        //     Object.keys(productsResp[gildId] || {}).forEach((product: any) => {
        //         const carriers = productsResp[gildId][product]
        //         carriers.forEach((carrier: string) => {
        //             const includes = productsResp[gildId][product][carrier]
        //             if (includes && classCodesResp[gildId].carrier_order.indexOf(product) === -1) {
        //                 classCodesResp[gildId].carrier_order.push(carrier)
        //             }
        //         })
        //     })
        // })
        // Object.keys(classCodesResp[gildId].carrier_order || {}).forEach((key: string) => {
        //     if (!classCodesResp[gildId].carrier_order || Array.isArray(classCodesResp[gildId].carrier_order)) {
        //         classCodesResp[gildId].carrier_order = products
        //     }
        //     classCodesResp[gildId].carrier_order[key] = classCodesResp[gildId].carrier_order[key].filter(
        //         (p: string) => Object.keys(products).indexOf(p) > -1
        //     )
        // })

        const useCodes = Object.values(classCodesResp)
            .sort((a: any, b: any) => b['Description'] > a['Description'] ? -1 : 1)
            .slice(start, end)
        useCodes.forEach((obj: any) => {
            allKeywords = allKeywords.concat(obj.Keywords)
            // if (obj.keywords && !Array.isArray(obj.keywords)) {
            //     obj.keywords = obj.keywords.split(',').map((k: string) => k.trim())
            //     allKeywords = allKeywords.concat(obj.keywords)
            // } else {
            //     console.log(obj.keywords)

            //     allKeywords = allKeywords.concat(obj.keywords).filter(k => !!k)
            // }
        })
        
        setClassCodes(useCodes)
        setKeywordOptions(Array.from(new Set(allKeywords)).sort())
    }

    useEffect(() => {
        if (autoSaveInterval) {
            clearInterval(autoSaveInterval)
        }
        autoSaveInterval = setInterval(() => {
            console.log('Auto-saving...')
            save()  // auto-save
        }, 60 * 5 * 1000)  // every five minutes
    }, [])
    
    useEffect(() => {
        if (token) {
            const headers = makeHeaders(token)
            const fetchGildCodes = axios({
                url: `${env.facadeBaseUrl}/appetite/carrier/gild/class_codes`,
                method: 'get',
                headers
            })
            const fetchProducts = axios({
                url: `${env.facadeBaseUrl}/appetite/products`,
                method: 'get',
                headers
            })

            Promise.all([fetchGildCodes, fetchProducts]).then((responses) => {
                classCodesResp = responses[0]['data']['class_codes']
                productsResp = responses[1]['data']
                console.log('productsResp', productsResp)

                setPageCount(Math.ceil(Object.keys(classCodesResp).length / 100))
                paginateResults()
            }).catch((err: any) => {
                authProvider.logout()
            })
        }
    }, [token])

    useEffect(() => {
        paginateResults()
        save()
    }, [page])

    useEffect(() => {
        save()
        if (classCodesResp) {
            if (!search) {
                paginateResults()
                return
            }
            const fuse = new Fuse(Object.values(classCodesResp), { keys: ['Description'], threshold: 0.3 })
            const result = fuse.search(search)
            setClassCodes(result.map(r => r.item))
        }
    }, [search])

    const onPaginationChange = (e: any, num: number) => {
        setPage(num)
    }

    const save = (onComplete?: Function) => {
        if (!isActive || !classCodesResp) {
            return
        }
        setSaving(true)
        setLastSaved(new Date().toLocaleTimeString())
        const url = `${env.facadeBaseUrl}/appetite/carrier/gild/class_codes`
        const headers = makeHeaders(token)
        axios({
            method: 'post',
            url,
            data: { class_codes: classCodesResp },
            headers
        })
        .then((resp => {
            setLastSaved(new Date().toLocaleTimeString())
        }))
        .catch(err => {
            console.error(err)
            setError(true)
            setErrorMsg(err.message)
        })
        .finally(() => {
            setSaving(false)
            if (onComplete) {
                onComplete()
            }
        })
    }

    return (
        <div style={(error) ? errorStyles : {}}>
            <Card style={{backgroundColor: 'white'}}>
                <Title title="Gild" />
                {classCodesResp ? (
                    <CardContent>
                        <Pagination sx={{ marginBottom: '16px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                        <Search setSearch={setSearch} />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 1300 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={50}>Active</TableCell>
                                        <TableCell width={50} align="left">Gild ID</TableCell>
                                        <TableCell width={350} align="left">Description</TableCell>
                                        <TableCell align="left" width={450}>Full Description</TableCell>
                                        <TableCell align="left" width={300}>Keywords</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {classCodes.map((code: any, idx) => {
                                        // console.log(code?.GildId, productsResp[code?.GildId], code?.carrier_order)
                                        return (
                                            <Row
                                                active={code?.active}
                                                carrierOrder={code?.carrier_order}
                                                deleteGildCode={deleteGildCode}
                                                description={code?.Description}
                                                fullDescription={code?.FullDescription}
                                                gildId={code?.GildId}
                                                key={code?.GildId}
                                                keywords={code?.Keywords}
                                                keywordOptions={keywordOptions}
                                                products={productsResp[code?.GildId] || {}}
                                            />
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination sx={{ margin: '16px 0 50px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                    </CardContent>
                ) : (
                    <LinearProgress color="secondary" />
                )}
                <Footer error={error} errorMsg={errorMsg} lastSaved={lastSaved} loading={saving} save={save} />
            </Card>
        </div>
    )
}

export default Gild
