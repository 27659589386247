import React, { useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import FilterAltIcon from '@mui/icons-material/FilterAlt'


interface Props {
    currentFilters: FilterProps[]
    setCurrentFilters: (filters: any[]) => void
}

interface FilterProps {
    active?: boolean
    idx?: number
    name: string
    value: {
        key: string
        value: any
    }
    toggleFilters?: any
}


const divStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row' as 'row',
}


const Filter = (props: FilterProps) => {
    const { active, idx, name, toggleFilters } = props

    const handleOnChange = (e: any, checked: boolean) => {
        toggleFilters(checked, idx)
    }

    return (
        <div style={{ ...divStyle, border: '1px solid #CCC', borderRadius: '7px', margin: '0 10px', padding: '5px 10px'}}>
            <Checkbox checked={active} onChange={handleOnChange} id={`filter--${name}`} />
            <label htmlFor={`filter--${name}`} style={{height: 'auto'}}>{name}</label>
        </div>
    )
}


const Filters = (props: Props) => {
    const { currentFilters = [], setCurrentFilters } = props

    const toggleFilters = (checked: boolean, idx: number) => {
        currentFilters[idx].active = checked
        setCurrentFilters([...currentFilters])
    }

    return (
        <div style={{...divStyle, marginBottom: '15px' }}>
            <Typography>Filters</Typography>
            <FilterAltIcon />
            <div style={divStyle}>
                {currentFilters.map((f: FilterProps, idx: number) => (
                    <Filter {...f} idx={idx} key={f.name} toggleFilters={toggleFilters} />
                ))}
            </div>
        </div>
    )
}

export default Filters