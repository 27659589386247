import React, { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import Fuse from 'fuse.js'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LinearProgress from '@mui/material/LinearProgress'
import Pagination from '@mui/material/Pagination'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Title } from 'react-admin'
import ClassCodeRow from './ClassCodeRow'
import authProvider from '../auth'
import { env } from '../env'
import Filters from '../components/Filters'
import Footer from '../Footer'
import { makeHeaders } from '../util'
import { ClassCodesResponse } from '../types'
import Search from '../Search'


interface Props {
    addNewGildCodes: any
    gildClasses?: any[]
    gildCodesResp: any
    isActive?: boolean
    productsResp?: any
    token?: string
    updateProducts?: any
}


let autoSaveInterval: any
let classCodesResp: ClassCodesResponse
let gildClassesMap: any = {}
// let questionsResp: any

const CARRIER = 'employers'
const errorStyles = {
    backgroundColor: 'rgba(255,0,0,.2)',
    border: '5px solid red'
}

const filters = [
    {active: false, name: 'Show Only Bindable', value: {key: 'inAppetite', value: true}}
]


const ClassCodes = (props: Props) => {
    const { addNewGildCodes, gildClasses = [], gildCodesResp, isActive, token } = props
    const [carrierCodes, setCarrierCodes] = useState<any[]>([])
    const [currentFilters, setCurrentFilters] = useState<any[]>(filters)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [lastSaved, setLastSaved] = useState('')
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(10)
    const [saving, setSaving] = useState(false)
    const [search, setSearch] = useState('')
    
    useEffect(() => {
        if (token) {
            const url = `${env.facadeBaseUrl}/appetite/carrier/${CARRIER}/class_codes`
            const headers = makeHeaders(token)
            axios({
                method: 'get',
                url,
                headers
            })
            .then((response: AxiosResponse<any>) => {
                if (response.data) {
                    classCodesResp = response.data['class_codes']
                    // TEMP
                    // const searchMap = Object.keys(gildClassesMap).map(key => ({ gild_id: key, description: gildClassesMap[key] }))
                    // const fuse = new Fuse(searchMap, { keys: ['description'], threshold: 0.3 })
                    Object.keys(classCodesResp).forEach(key => {
                        const obj: any = classCodesResp[key]
                        const bindable = obj['inAppetite']
                        if (!bindable && !obj['manual']) {
                            obj['manual'] = obj['products']
                            obj['products'] = {}
                        }
                    })
                    // Object.keys(classCodesResp).forEach(key => {
                    //     const obj: any = classCodesResp[key]
                    //     const results = fuse.search(obj.description)
                    //     if (results.length) {
                    //         console.log(obj.description, results)
                    //         obj.gildCode = results.map(r => r.item.gild_id)
                    //     }
                    //     if (obj.active === undefined) {
                    //         obj.active = true
                    //     }
                    //     if (typeof obj.inAppetite !== 'boolean') {
                    //         obj.inAppetite = obj.inAppetite === 'TRUE' ? true : false
                    //     }
                    //     if (typeof obj.isTargeted !== 'boolean') {
                    //         obj.isTargeted = obj.isTargeted === 'TRUE' ? true : false
                    //     }
                    //     if (!Array.isArray(obj.gildCode)) {
                    //         obj.gildCode = obj.gildCode ? [obj.gildCode] : []
                    //     }
                    //     if (obj.products === undefined || Array.isArray(obj.products)) {
                    //         obj.products = {}
                    //     }
                    //     delete obj.gild_id
                    // })
                    //
                    console.log(gildCodesResp)
                    setPageCount(Math.ceil(Object.keys(classCodesResp).length / 100))
                    paginateResults()
                }
            })
            .catch((err: any) => {
                authProvider.logout()
            })
        }
    }, [token])

    useEffect(() => {
        paginateResults()
        save()
    }, [currentFilters, page])

    useEffect(() => {
        save()
        if (classCodesResp) {
            if (!search) {
                paginateResults()
                return
            }
            const fuse = new Fuse(Object.values(classCodesResp), { keys: ['description'], threshold: 0.3 })
            const result = fuse.search(search)
            setCarrierCodes([])  // set to empty array to trigger a state change
            setTimeout(() => {
                setCarrierCodes(result.map(r => r.item))
            }, 250)
        }
    }, [search])

    const gildMenuItems = gildClasses.map(c => {
        gildClassesMap[c['code']] = c['description']
        return {'value': c['code'], 'label': c['description']}
    })        

    const paginateResults = () => {
        if (!classCodesResp) {
            return
        }
        const start = 100 * (page - 1)
        const end = 100 * page
        const filteredCodes = Object.values(classCodesResp).filter(code => {
            let include = true
            currentFilters.forEach(filter => {
                if (filter.active) {
                    // @ts-ignore
                    if (code[filter.value.key] !== filter.value.value) {
                        include = false
                    }
                }
            })
            return include
        })

        const useCodes = filteredCodes.slice(start, end)
        setCarrierCodes(useCodes)
    }

    const save = (onComplete?: Function) => {
        if (!isActive || !classCodesResp) {
            console.log('Tab inactive or missing classCodesResp')
            return
        }
        setSaving(true)
        console.log(classCodesResp)
        setLastSaved(new Date().toLocaleTimeString())
        const url = `${env.facadeBaseUrl}/appetite/carrier/${CARRIER}/class_codes`
        const headers = makeHeaders(token)
        axios({
            method: 'post',
            url,
            data: { class_codes: classCodesResp },
            headers
        })
        .then((resp => {
            setLastSaved(new Date().toLocaleTimeString())
        }))
        .catch(err => {
            console.error(err)
            setError(true)
            setErrorMsg(err.message)
        })
        .finally(() => {
            setSaving(false)
            if (onComplete) {
                onComplete()
            }
        })
    }

    const onPaginationChange = (e: any, num: number) => {
        setPage(num)
    }

    const updateClassCodesResp = (key: string, update: any) => {
        Object.assign(classCodesResp[key], update)
    }

    if (autoSaveInterval) {
        clearInterval(autoSaveInterval)
    }
    autoSaveInterval = setInterval(() => {
        console.log('Auto-saving...')
        save()  // auto-save
    }, 60 * 5 * 1000)  // every five minutes

    return (
        <div style={(error) ? errorStyles : {}}>
            {classCodesResp ? (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Pagination sx={{ marginBottom: '16px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                        <Filters currentFilters={currentFilters} setCurrentFilters={setCurrentFilters} />
                    </div>
                    <Search setSearch={setSearch} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={50}>Active</TableCell>
                                    <TableCell width={50} align="left">Class Code</TableCell>
                                    <TableCell width={300} align="left">Employers Class</TableCell>
                                    <TableCell align="left" width={600}>Gild Description</TableCell>
                                    <TableCell align="left">API Product</TableCell>
                                    <TableCell align="left">Manual Product</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {carrierCodes.map((code: any, idx) => {
                                    return (
                                        <ClassCodeRow
                                            {...code}
                                            addNewGildCodes={addNewGildCodes}
                                            key={code['classCode']}
                                            gildClassesMap={gildClassesMap}
                                            gildMenuItems={gildMenuItems}
                                            update={updateClassCodesResp} />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination sx={{ marginBottom: '16px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                    <Footer error={error} errorMsg={errorMsg} lastSaved={lastSaved} loading={saving} save={save} />
                </>
            ) : (
                <LinearProgress color="secondary" />
            )}
        </div>
    )
}


const Employers = (props: Props) => {
    const [tab, setTab] = useState(0)

    return (
        <Card style={{backgroundColor: 'white'}}>
            <Title title="Employers" />
            <CardContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '15px' }}>
                    <Tabs value={tab} onChange={(e: any, newVal: number) => setTab(newVal)}>
                        <Tab label="Class Codes" value={0} />
                    </Tabs>
                </Box>
                {(tab === 0) && <ClassCodes {...props} />}
            </CardContent>
        </Card>
    )
}

export default Employers
