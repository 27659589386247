import { SidebarToggleButton } from 'react-admin'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'



const style = {
    backgroundColor: 'white',
    color: 'black',
    flexDirection: 'row'
}


const CustomAppBar = (props: any) => (
    <AppBar {...props} sx={style}>
        <Toolbar sx={{justifyContent: 'space-between'}}>
            <SidebarToggleButton />
            <Typography variant="h6" id="react-admin-title" sx={{flexGrow: 1}} />
        </Toolbar>
    </AppBar>
)

export default CustomAppBar