import Auth from '@aws-amplify/auth'


const logout = async () => {
    return await Auth.signOut()
}

export default {
  // called when the user attempts to log in
  login: async () => {
    const session = await Auth.currentSession()
    const accessJwt = session.getAccessToken().getJwtToken()
    if (accessJwt) {
      return Promise.resolve()
    }
    return Promise.resolve()
  },
  // called when the user clicks on the logout button
  logout: async () => {
    await logout()
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: async ({ status }: { status: number}) => {
      if (status === 401 || status === 403) {
          await logout()
          return Promise.reject()
      }
      return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: async () => {
    const session = await Auth.currentSession()
    const accessJwt = session.getAccessToken().getJwtToken()
    if (accessJwt) {
      return Promise.resolve()
    }
    return Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    const user =  await Auth.currentAuthenticatedUser()
    const groups = user.signInUserSession.accessToken.payload['cognito:groups']
    if (!groups) {
      return Promise.reject()
    }
    return Promise.resolve(groups)
  },
}