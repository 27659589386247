import { useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import Fuse from 'fuse.js'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import LinearProgress from '@mui/material/LinearProgress'
import Pagination from '@mui/material/Pagination'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Title } from 'react-admin'
import authProvider from '../auth'
import { env } from '../env'
import Footer from '../Footer'
import ClassCodeRow from './ClassCodeRow'
import QuestionRow, { QuestionHeader } from './QuestionRow'
import { makeHeaders } from '../util'
import Search from '../Search'


interface Props {
    addNewGildCodes: any
    gildClasses?: any[]
    isActive?: boolean
    productsResp?: any
    token?: string
    updateProducts?: any
}


let autoSaveInterval: any
let classCodesResp: any
let gildClassesMap: any = {}
let questionsResp: any

const CARRIER = 'chubb'
const errorStyles = {
    backgroundColor: 'rgba(255,0,0,.2)',
    border: '5px solid red'
}


const ClassCodes = (props: Props) => {
    const { addNewGildCodes, gildClasses = [], isActive, token } = props
    const [carrierCodes, setCarrierCodes] = useState<any[]>([])
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [lastSaved, setLastSaved] = useState('')
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(10)
    const [saving, setSaving] = useState(false)
    const [search, setSearch] = useState('')

    useEffect(() => {
        if (autoSaveInterval) {
            clearInterval(autoSaveInterval)
        }
        autoSaveInterval = setInterval(() => {
            console.log('Auto-saving...')
            save()  // auto-save
        }, 60 * 5 * 1000)  // every five minutes
    }, [])
    
    useEffect(() => {
        if (token) {
            const url = `${env.facadeBaseUrl}/appetite/carrier/${CARRIER}/class_codes`
            const headers = makeHeaders(token)
            axios({
                method: 'get',
                url,
                headers
            })
            .then((response: AxiosResponse<any>) => {
                if (response.data) {
                    classCodesResp = response.data['class_codes']                    
                    // TEMP
                    Object.keys(classCodesResp).forEach(key => {
                        const obj = classCodesResp[key]
                        const bindable = obj['API']
                        if (!bindable && !obj['manual']) {
                            obj['manual'] = obj['products']
                            obj['products'] = {}
                        }
                    })
                        // // if (obj.gild_id) {
                        // //     obj.gildCode = obj.gild_id
                        // //     delete obj.gild_id
                        // }
                        //     if (obj.gild_desc) {
                    //         obj.active = true
                    //         gildClasses.some(clsObj => {
                    //             if (clsObj.description === obj.gild_desc) {
                    //                 obj.gild_id = clsObj.code
                    //                 return true
                    //             }
                    //             return false
                    //         })
                    //     }
                    //     if (!Array.isArray(obj.gild_id)) {
                    //         obj.gild_id = [obj.gild_id || '']
                    //     }
                    // })
                    // //
                    setPageCount(Math.ceil(Object.keys(classCodesResp).length / 100))
                    paginateResults()
                }
            })
            .catch((err: any) => {
                authProvider.logout()
            })
        }
    }, [token])

    useEffect(() => {
        paginateResults()
        save()
    }, [page])

    useEffect(() => {
        save()
        if (classCodesResp) {
            if (!search) {
                paginateResults()
                return
            }
            const fuse = new Fuse(Object.values(classCodesResp), { keys: ['Description'], threshold: 0.3 })
            const result = fuse.search(search)
            setCarrierCodes([])  // set to empty array to trigger a state change
            setTimeout(() => {
                setCarrierCodes(result.map(r => r.item))
            }, 250)
        }
    }, [search])

    const gildMenuItems = gildClasses.map(c => {
        gildClassesMap[c['code']] = c['description']
        return {'value': c['code'], 'label': c['description']}
    })        

    const paginateResults = () => {
        if (!classCodesResp) {
            return
        }
        const start = 100 * (page - 1)
        const end = 100 * page
        const useCodes = Object.values(classCodesResp).slice(start, end)
        setCarrierCodes([])  // set to empty array to trigger a state change
        setTimeout(() => {
            setCarrierCodes(useCodes)
        })
    }

    const save = (onComplete?: Function) => {
        if (!isActive || !classCodesResp) {
            return
        }
        setSaving(true)
        setLastSaved(new Date().toLocaleTimeString())
        const url = `${env.facadeBaseUrl}/appetite/carrier/${CARRIER}/class_codes`
        const headers = makeHeaders(token)
        axios({
            method: 'post',
            url,
            data: { class_codes: classCodesResp },
            headers,
        })
        .then((resp => {
            setLastSaved(new Date().toLocaleTimeString())
        }))
        .catch(err => {
            console.error(err)
            setError(true)
            setErrorMsg(err.message)
        })
        .finally(() => {
            setSaving(false)
            if (onComplete) {
                onComplete()
            }
        })
    }

    const onPaginationChange = (e: any, num: number) => {
        setPage(num)
    }

    const updateClassCodesResp = (key: string, update: any) => {
        Object.assign(classCodesResp[key], update)
    }

    return (
        <div style={(error) ? errorStyles : {}}>
            {classCodesResp ? (
                <>
                    <Pagination sx={{ marginBottom: '16px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                    <Search setSearch={setSearch} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={50}>Active</TableCell>
                                    <TableCell width={50} align="left">Class Code</TableCell>
                                    <TableCell width={300} align="left">Chubb Class</TableCell>
                                    <TableCell align="left" width={600}>Gild Description</TableCell>
                                    <TableCell align="left">API Product</TableCell>
                                    <TableCell align="left">Manual Product</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {carrierCodes.map((code: any) => {
                                    return (
                                        <ClassCodeRow
                                            {...code}
                                            addNewGildCodes={addNewGildCodes}
                                            key={code['classCode']}
                                            gildClassesMap={gildClassesMap}
                                            gildMenuItems={gildMenuItems}
                                            update={updateClassCodesResp} />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination sx={{ marginBottom: '16px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                    <Footer error={error} errorMsg={errorMsg} lastSaved={lastSaved} loading={saving} save={save} />
                </>
            ) : (
                <LinearProgress color="secondary" />
            )}
        </div>
    )
}


const Questions = (props: Props) => {
    const { isActive, token } = props
    const [lastSaved, setLastSaved] = useState('')
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(10)
    const [questions, setQuestions] = useState<any[]>([])
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (token) {
            const url = `${env.facadeBaseUrl}/appetite/carrier/${CARRIER}/questions`
            const headers = makeHeaders(token)
            axios({
                method: 'get',
                url,
                headers
            })
            .then((response: AxiosResponse<any>) => {
                if (response.data) {
                    questionsResp = response.data['questions']
                    // TEMP
                    Object.keys(questionsResp).forEach(key => {
                        const question = questionsResp[key]
                        if (!question.gild_version) {
                            question.gild_version = question['Rules Condition']   
                        }
                    })
                    //
                    setPageCount(Math.ceil(Object.keys(questionsResp).length / 100))
                    paginateResults()
                }
            })
        }
    }, [token])

    useEffect(() => {
        paginateResults()
    }, [page])

    const paginateResults = () => {
        if (!questionsResp) {
            return
        }
        const start = 100 * (page - 1)
        const end = 100 * page
        const useQuestions = Object.values(questionsResp).slice(start, end)
        console.log("questions", questionsResp)
        setQuestions(useQuestions)
    }

    const save = () => {
        if (!isActive || !classCodesResp) {
            return
        }
        setSaving(true)
        setLastSaved(new Date().toLocaleTimeString())
        const url = `${env.facadeBaseUrl}/appetite/carrier/${CARRIER}/questions`
        const headers = makeHeaders(token)
        axios({
            method: 'post',
            url,
            data: { questions: questionsResp },
            headers
        })
        .then((resp => {
            setLastSaved(new Date().toLocaleTimeString())
        }))
        .catch(err => {

        })
        setSaving(false)
    }

    const onPaginationChange = (e: any, num: number) => {
        setPage(num)
    }

    const updateQuestionsResp = (key: string, update: any) => {
        Object.assign(questionsResp[key], update)
    }

    return (
        <>
            {questionsResp ? (
                <>
                    <Pagination sx={{ marginBottom: '16px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <QuestionHeader />
                            </TableHead>
                            <TableBody>
                                {questions.map((question: any, idx: number) => {
                                    const code = question['Rule Code']
                                    return (
                                        <QuestionRow
                                            key={code}
                                            classCodesResp={classCodesResp}
                                            code={code}
                                            question={question}
                                            update={updateQuestionsResp} />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination sx={{ marginBottom: '16px', marginTop: '16px' }} count={pageCount} page={page} onChange={onPaginationChange} color="secondary" variant="outlined" shape="rounded" />
                    <Footer lastSaved={lastSaved} loading={saving} save={save} />
                </>
            ) : (
                <LinearProgress color="secondary" />
            )}
            
        </>
    )
}


const Chubb = (props: Props) => {
    const [tab, setTab] = useState(0)

    const onTabChange = (e: any, newVal: number) => {
        if (!classCodesResp) {
            return
        }
        let url = `${env.facadeBaseUrl}/appetite/carrier/${CARRIER}/`
        const headers = makeHeaders(props.token)
        let data
        switch (tab) {
            case 0:
                url += 'class_codes'
                data = { class_codes: classCodesResp }
                break;
            case 1:
                url += 'questions'
                data = { questions: questionsResp }
                break;
        }
        axios({
            method: 'post',
            url,
            data,
            headers
        })
        .then((resp => {
            console.log(resp)
        }))
        .catch(err => {

        })
        .finally(() => setTab(newVal))
    
    }

    return (
        <Card style={{backgroundColor: 'white'}}>
            <Title title="Chubb" />
            <CardContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '15px' }}>
                    <Tabs value={tab} onChange={onTabChange}>
                        <Tab label="Class Codes" value={0} />
                        <Tab label="Questions" value={1} />
                    </Tabs>
                </Box>
                {(tab === 0) && <ClassCodes {...props} />}
                {(tab === 1)  && <Questions {...props} />}
            </CardContent>
        </Card>
    )
}

export default Chubb
