import Product from './Product'


interface Props {
    classCode: string
    color: 'primary' | 'secondary'
    onProductsChange: any
    products: any
}


const ProductsList = (props: Props) => {
    const {classCode, color = 'primary', products, onProductsChange } = props
    return (
        <>
            <Product color={color} checked={products?.bop} code={classCode} onChange={onProductsChange} product="BOP" />
            <Product color={color} checked={products?.pl} code={classCode} onChange={onProductsChange} product="PL" />
            <Product color={color} checked={products?.gl} code={classCode} onChange={onProductsChange} product="GL" />
            <Product color={color} checked={products?.wc} code={classCode} onChange={onProductsChange} product="WC" />
            <Product color={color} checked={products?.['commercial-auto']} code={classCode} onChange={onProductsChange} product="Commercial Auto" />  
        </>
    )
}
export default ProductsList