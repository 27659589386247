import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'


const fixedFooterStyle = {
    alignItems: 'center',
    background: '#fff',
    bottom: 0,
    boxShadow: '5px 0 5px 0 rgba(0,0,0,.2)',
    display: 'flex',
    flexDirection: 'row' as 'row',
    height: 50,
    justifyContent: 'flex-end',
    left: 0,
    position: 'fixed' as 'fixed',
    right: 0,
    zIndex: 1
}

const buttonStyle = {
    marginRight: 25
}
const errorStyle = {
    backgroundColor: 'rgba(255,0,0,.2)',
    color: 'red',
    cursor: 'not-allowed',
    marginRight: 25
}


interface Props {
    error?: boolean
    errorMsg?: string
    lastSaved?: string
    loading: boolean
    save: any
}


const Footer = (props: Props) => {
    const { error, errorMsg, lastSaved, loading, save } = props
    const [saving, setSaving] = useState(false)  // internal "loading" tracking
    const handleSave = () => {
        if (!error) {
            setSaving(true)
            setTimeout(() => {
                save(() => setSaving(false))
            }, 250)
        }
    }
    return (
        <div className="fixed-footer" style={fixedFooterStyle}>
            <p style={{color: lastSaved ? 'grey' : 'red', fontSize: 12, marginRight: 25 }}>
                {errorMsg ?
                    (<em style={{color: 'red'}}>{errorMsg}</em>)
                :
                    (<em>{lastSaved ? `Last saved at ${lastSaved}` : 'Not saved'}</em>)
                }
                
            </p>
            <LoadingButton color="primary" loading={saving || loading} onClick={handleSave} style={error ? errorStyle : buttonStyle}>{error ? 'Error' : 'Save'}</LoadingButton>
        </div>
    )
}

export default Footer