import React, { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'


interface RowProps {
    // active?: boolean
    classCodesResp: any
    code: string
    question: any
    // description: string
    // gildClassesMap: any
    // gildCode?: string[]
    // gildMenuItems: any
    // products?: {[key: string]: boolean}
    update?: any
}

const textFieldStyle = {
    overflow: 'scroll',
    maxHeight: '150px',
    fontSize: '15px !important',
    lineHeight: 1.2,
    width: '300px'
}


export const QuestionHeader = (props: any) => {
    return (
        <TableRow>
            <TableCell width={50}>Code</TableCell>
            <TableCell width={50}>Rule ID</TableCell>
            <TableCell width={50}>Question Type</TableCell>
            <TableCell width={50} align="left">Answer Type</TableCell>
            <TableCell align="left" width={300}>Original</TableCell>
            <TableCell align="left" width={400}>Gild Version</TableCell>
            <TableCell width={200}>Logic</TableCell>
            <TableCell width={200}>Chubb Classes</TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}


const Row = (props: RowProps) => {
    const { classCodesResp, code, question, update } = props
    const [gildVersion, setGildVersion] = useState<string>(question.gild_version)

    const chubbCodes = question['TTS Codes'].map((tts: string) => {
        try {
            return classCodesResp[parseInt(tts)].Description || tts
        } catch {
            return tts
        }
    })

    const onGildVersionChange = (e: any) => {
        update(code, { gild_version: e.target.value })
        setGildVersion(e.target.value)
    }
    
    return (                            
        <TableRow key={code}>
            <TableCell>{code}</TableCell>
            <TableCell>{question['Legacy Requirement ID']}</TableCell>
            <TableCell>{question['Rule Type -- Referral, Declination, Question, Product']}</TableCell>
            <TableCell>{question['Answer Type']}</TableCell>
            <TableCell>{question['Rules Condition']}</TableCell>
            <TableCell>
                <TextField sx={{width: '100%'}} multiline onChange={onGildVersionChange} value={gildVersion} />
            </TableCell>
            <TableCell>{question['Logic to show the question']}</TableCell>
            <TableCell>
                <TextField
                    sx={{ textFieldStyle }}
                    multiline
                    aria-readonly 
                    value={`${chubbCodes.join(';\r\n ')}`} 
                />
            </TableCell>
            <TableCell>
                <Tooltip title={Object.keys(question).map(key => `${key}: ${question[key]}`).join(', ')}>
                    <InfoIcon />
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}

export default Row